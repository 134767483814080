import { EmailValidation } from '@/utils/valitation/subscribe';
import { getCookies } from 'cookies-next';
import { Field, Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { subscribeToNewsletter } from '@/services/newsletter';
import NewsLetterIcon from '@/components/icons/NewsLetterIcon';
import { useRouter } from 'next/router';

const EmailSubscribe = ({ t }) => {
  const { handshake } = getCookies();
  const { locale } = useRouter();
  return (
    <>
      <h3 className='mb-4 font-bold'>{t('Sign up to Newsletter')}</h3>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={EmailValidation}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const res = await subscribeToNewsletter(handshake, values.email);
          if (res.status == 200) {
            !res.data.data.at(0).error
              ? toast.success(
                  locale === 'en'
                    ? res.data.data.at(0).message
                    : 'تم الإشتراك بنجاح'
                )
              : toast.error(
                  locale === 'en'
                    ? res.data.data.at(0).message
                    : 'هذا البريد مسجل من قبل'
                );
          } else {
            toast.error(
              locale === 'en'
                ? res.error.error.at(0).message
                : 'حدث خطأ ما, برجاء المحاولة مره اخرى'
            );
          }

          setSubmitting(false);
          resetForm();
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form className='flex items-center gap-3 border-b border-white border-opacity-30 pb-3 sm:w-5/6'>
            <div className='w-full'>
              <Field
                name='email'
                placeholder={t('Email Address')}
                className='w-full bg-[#D6D6D6] px-2 py-1 text-slate-700 outline-none max-sm:text-sm max-sm:placeholder:text-sm sm:py-3'
              />
              {errors.email && touched.email ? (
                <p className='flex-1 text-sm text-red-600'>{t(errors.email)}</p>
              ) : null}
            </div>
            <button type='submit' disabled={isSubmitting}>
              {/* {isSubmitting ? t('Loading...') : t('Subscribe')} */}
              {isSubmitting ? t('Loading...') : <NewsLetterIcon />}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EmailSubscribe;
